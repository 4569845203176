<template>
  <v-row justify="end">
    <v-dialog v-model="isVisibleProductEdit">
      <v-form ref="form" v-model="is_valid" lazy-validation v-on:submit.prevent>
        <v-card>
          <v-card-title class="pb-4">
            <span class="text-h3">{{ $t("physical_qte") }}</span>
          </v-card-title>
          <v-card-text class="pa-0 grey lighten-4">
            <v-container>
              <v-card>
                <v-card-text>
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">
                            {{ $t("Batch Number") }}
                          </th>
                          <th class="text-left">
                            {{ $t("batch_quantity") }}
                          </th>

                          <th class="text-left">
                            {{ $t("batch_quantity_real") }}
                          </th>

                          <th class="text-left">
                            {{ $t("Expiration Date") }}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(lots, i) in product.adjustement_lots"
                          :key="i"
                        >
                          <td>{{ lots.lot_number }}</td>
                          <td>{{ lots.qte_in_lot }}</td>
                          <td>
                            <v-text-field
                              :disabled="!lots.can_update_qte"
                              dense
                              hide-details
                              outlined
                              type="number"
                              @input="updateGlobaleQuantity()"
                              v-model="lots.adjustment_quantity"
                              :rules="[rules.required, rules.superior_to(0)]"
                            />
                          </td>
                          <td>
                            <v-text-field
                              :disabled="!lots.can_update_qte"
                              dense
                              hide-details
                              outlined
                              type="date"
                              v-model="lots.expiration_date"
                              :rules="[rules.required]"
                            />
                          </td>
                        </tr>
                      </tbody>
                      <thead>
                        <tr>
                          <th class="text-left">
                            {{ $t("name") }}
                          </th>
                          <th class="text-left">
                            {{ $t("theoretical_qte") }}
                          </th>
                          <th class="text-left">
                            {{ $t("physical_qte") }}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(
                            inventoryAdjst, i
                          ) in product.inventory_adjustment"
                          :key="i"
                        >
                          <td>{{ i == 0 ? product.name : "" }}</td>
                          <td>
                            {{ product.theoretical_qte }}
                          </td>
                          <td v-if="!inventoryAdjst.can_update_qte">
                            <v-text-field
                              disabled
                              dense
                              hide-details
                              outlined
                              type="number"
                              v-model="inventoryAdjst.adjustment_quantity"
                            ></v-text-field>
                          </td>
                          <td v-else>
                            <v-text-field
                              dense
                              readonly
                              hide-details
                              outlined
                              type="number"
                              v-model="product.physical_qte"
                              :rules="[rules.required, rules.superior_to(0)]"
                            ></v-text-field>
                          </td>
                        </tr>

                        <!-- <tr
                          v-if="
                            product.inventory_adjustment.length <
                            this.max_product_quantity_adjustment
                          "
                        >
                          <td>{{ product.name }}</td>
                          <td>{{ product.theoretical_qte }}</td>
                          <td>
                            {{ product.theoretical_qte }}
                          </td>
                          <td v-if="!inventoryAdjst.can_update_qte">
                            <v-text-field
                              disabled
                              dense
                              hide-details
                              outlined
                              type="number"
                              v-model="inventoryAdjst.adjustment_quantity"
                            ></v-text-field>
                          </td>
                          <td v-else>
                            <v-text-field
                              dense
                              hide-details
                              outlined
                              type="number"
                              v-model="product.physical_qte"
                              @keyup.enter="updatePhysicalQte(product)"
                              :rules="[rules.required, rules.superior_to(0)]"
                            ></v-text-field>
                          </td>
                        </tr> -->
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card-text>
              </v-card>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-btn color="red darken-1" text @click="closeForm()">
              {{ $t("close") }}
            </v-btn>
            <v-btn
              color="primary"
              text
              @click="updatePhysicalQte(product)"
              :disabled="!is_valid"
            >
              {{ $t("save") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </v-row>
</template>

<script>
import { validationRules } from "@/mixins/validationRules";

import { mapGetters } from "vuex";

export default {
  mixins: [validationRules],

  computed: {
    ...mapGetters({
      product: "inventories/product",
      isVisibleProductEdit: "inventories/isVisibleProductEdit",
      isVisibleProductShow: "inventories/isVisibleProductShow",
      // productsMeta: "products/meta",
      hubs: "hubs/activeHubs",
    }),
  },

  data() {
    return {
      is_valid: true,
      max_product_quantity_adjustment: 3,
    };
  },

  methods: {
    updateGlobaleQuantity() {
      let quantity = 0;
      this.product?.adjustement_lots.forEach(function (v) {
        if (v.can_update_qte == true) {
          quantity = parseInt(quantity) + parseInt(v.adjustment_quantity);
        }
      });
      this.product.physical_qte = quantity;
      console.log(this.product.physical_qte);
    },
    closeForm() {
      this.$refs.form.reset();
      this.$store.dispatch("inventories/closeProductForm");
    },
    async updatePhysicalQte(product) {
      if (this.$refs.form.validate()) {
        try {
          var updatedProduct = await this.$store.dispatch(
            "inventories/updateProduct",
            product
          );

          this.$store.dispatch(
            "alerts/success",
            this.$t("updated_successfully")
          );

          if (updatedProduct.notify_product_max_adjustment) {
            setTimeout(() => {
              this.$store.dispatch(
                "alerts/info",
                this.$t("product_qte_max_adjustment_notification")
              );
            }, 1000);
          }

          this.closeForm();
        } catch (error) {
          this.$store.dispatch("alerts/error", error.response.data.message);
        }
      }
    },
  },
};
</script>
